import { getTimestampForStartOfMonth, getTimestampForStartOfDay, timestampToDateString, getDateString } from "./datetimeUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime"
export const monthlyViewsToDailyViewsFromDate = (monthlyViewsById={}, fromDate) => {
    /**
     * Generate an array of daily views from the monthly views interface
     */
    if (!fromDate || typeof fromDate !== "number" || typeof monthlyViewsById !== "object") return [] 
    const startOfMonth = getTimestampForStartOfMonth(fromDate, false)
    const dailyViews = []
    Object.values(monthlyViewsById)
          .filter(monthlyViews => monthlyViews.monthStartAt >= startOfMonth)
          .sort((mA, mB) => mA.monthStartAt - mB.monthStartAt)
          .forEach(monthlyView => {
                /** Generate a view for every day of the month from the start date */
                //1. get the start of day time for the from date, 
                    //we will loop forward from this day
                    //for the month within which the from date lands, we use the from date
                    //otherwise, we use the start of that month  
                const fromDateInMonth = fromDate > monthlyView.monthStartAt ?
                                    fromDate
                                    :
                                    monthlyView.monthStartAt
                const startOfDay = getTimestampForStartOfDay(fromDateInMonth)
                const startOfThisMonth = getTimestampForStartOfMonth(fromDateInMonth, false)
                //2. get the start of next month, we will end the loop at this day
                const startOfNextMonth = getTimestampForStartOfMonth(
                    startOfThisMonth + (32 * DAY_IN_MILLISECONDS)
                )
                //3. loop through each day of the month, from the start date
                //   stop at the end of the month
                for (let day = startOfDay; day < startOfNextMonth; day=day + DAY_IN_MILLISECONDS){
                    //4. for each day, if there are views that day, set that number of views, else set zero
                    const {viewsByDateString={}} = monthlyView
                    const dateString = getDateString(day)
                    dailyViews.push({
                        date: timestampToDateString(day, "MMM dd"),
                        views: viewsByDateString[dateString] ? viewsByDateString[dateString] : 0,
                        dayStartAt: day
                    })
                }
          })
    
    return dailyViews
}