import React, {useEffect} from "react"
import ScrollToTop from '../../components/ScrollToTop'
import Loading from "../../components/Loading"
import {Outlet} from "react-router-dom"

import {connect} from "react-redux"
//components
import ErrorBoundary from "../../components/ErrorBoundary";
import Logo from "../../images/logo100x143.png"
//this wraps the routes in the RouterProvider
function AppLayout ({system}){  
    // useEffect(() => {
    //   const handleUnhandledRejection = (event) => {
    //     // console.error('Unhandled promise rejection:', event.reason, event);

    //     console.error("Unhandled promise rejection:", event.reason, event);
    //     if (event.reason === null) {
    //         throw new Error("Promise rejected with null! Check stack trace.");
    //     }
    //   };
    //   window.addEventListener('unhandledrejection',handleUnhandledRejection);
    //   return () => {
    //     window.removeEventListener('unhandledrejection',handleUnhandledRejection);
    //   }
    // }, [])
    return (
      <ErrorBoundary
            className="main padding-1em display-flex align-items-center justify-content-center flex-direction-column"
            errorHeading={
              <div className="">
                <div><img className="navLogo" src={Logo} alt="SHOPDM PAY"/></div>
                <div>Something went wrong. </div>
                <div className="font-size-14px">Try again, if it happens again, contact the Shopdm Pay tech team at +17676128202 or pay@shopdm.store</div>
                <div className="font-weight-200 margin-top-1em">They'll tell you they're sorry 😭 and ask you for the error. Tell them the error is:</div>
              </div>
            }
            errorContext="AppLayout Top-level error boundary"
        >
      <div>
        {
            system.loading ? 
            <Loading />
            :
            null
        }
        <ScrollToTop />
        <Outlet />
      </div>
      </ErrorBoundary>
    )
  }

const mapStateToProps = state => ({
    system: state.system,
})

export default connect(mapStateToProps)(AppLayout)