import React from "react"

import Skeleton from '@mui/material/Skeleton';
import AmountSmallAfterDecimal from "../AmountSmallAfterDecimal"
import TextDisplayFormatter from "../TextDisplayFormatter";
import ProductVariantPicker from "../ProductVariantPicker";
import styles from "./PayeeProduct.module.css"
const PayeeProduct = ({
    imageUrl="", name="", description="", price=0, totalXcd=0, selectedQuantity=0, loaded=true,
    variantsById={}, hasVariants=false, selectedVariantId="", onChangeSelectedVariant=()=>{}
}) => {
    return (
        <div className="text-align-center">
            {
                loaded ?
                <>
                    <img className="payeeProduct margin-bottom-05em" src={imageUrl}/>
                    <div className="font-size-14px">{selectedQuantity > 0 ? `${selectedQuantity} x `: ""}{name} <span className="color-grey font-size-12px">{selectedQuantity > 1 ? `($${price} each)` : ""}</span></div>
                    <div className="font-weight-600">EC $<AmountSmallAfterDecimal amount={totalXcd ? totalXcd : price} /></div>
                    {
                        hasVariants ?
                        <div className={`productVariantPickerContainer ${styles.variantPicker}`}>
                            <ProductVariantPicker 
                                variantsById={variantsById} 
                                selectedVariantId={selectedVariantId}
                                productImageUrl={imageUrl}
                                onChange={selectedVariantId => onChangeSelectedVariant(selectedVariantId)}
                            />
                        </div>
                        :
                        ""
                    }
                    {
                        description ?
                    <div className="font-size-12px margin-top-05em margin-bottom-05em"><TextDisplayFormatter>{description}</TextDisplayFormatter></div>
                    :
                    ""
                    }
                </>
                :
                <>
                    <Skeleton variant="rounded" width={125} height={70} />
                    <div className="margin-top-05em">
                    <Skeleton variant="rectangular" width={125} height={50} />
                    </div>
                </>
            }
        </div>
    )
}

export default PayeeProduct