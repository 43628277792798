import React from "react"
import ProductListItem from "../../containers/ProductListItem"
import styles from "./ProductList.module.css"
import {VIEW_TYPE_LIST, VIEW_TYPE_GRID, DISPLAY_TYPE_ROW, DISPLAY_TYPE_TILE} from "../../constants/interface"

const ProductList = ({
    productList=[], 
    viewType=VIEW_TYPE_LIST, 
    getDefaultActiveVariantId=()=>"", //function to get the default selected variant for each product
    activeVariantEditable=true //do we allow changed to the active variant
}) => {
    let productDisplayType = undefined
    let viewTypeStyle = ""
    if (VIEW_TYPE_LIST === viewType){
        productDisplayType = DISPLAY_TYPE_ROW
        viewTypeStyle = styles.list
    } else if (VIEW_TYPE_GRID === viewType){
        productDisplayType = DISPLAY_TYPE_TILE 
        viewTypeStyle = ""
    }
    return (
        <div className={`${styles.container} ${viewTypeStyle}`}>
            {
                productList.map(p => {
                                    if (!p) return ""
                                    const defaultActiveVariantId = getDefaultActiveVariantId(p)
                                    const variantKey = defaultActiveVariantId ? defaultActiveVariantId : p.defaultVariantId
                                    return (
                                        <div key={`${p.id}${variantKey}`} className={styles.item}>
                                            <ProductListItem 
                                                product={p}
                                                displayType={productDisplayType}
                                                defaultActiveVariantId={defaultActiveVariantId}
                                                activeVariantEditable={activeVariantEditable}
                                            />
                                        </div>
                                    )
                                })
            }
        </div>
    )
}

export default ProductList