import React, {useEffect, useRef} from "react"
import ProductVariantOption from "../ProductVariantOption"
import { alphabeticalSort } from "../../utils/stringUtils"
import styles from "./ProductVariantPicker.module.css"

const ProductVariantPicker = ({variantsById={}, selectedVariantId="", defaultImageUrl="", onChange=()=>{}, editable=true}) => {
    const selectedVariant = typeof variantsById !== 'object' ? null :
                            variantsById[selectedVariantId]
    const selectedVariantIsinactive = selectedVariant ? selectedVariant.isInactive : false
    const selectedVariantRef = useRef(null); // Ref to track the selected variant
    useEffect(() => {
        //if the selected variant is deleted or inactive, switch to the next active variant
        if (!selectedVariant || (selectedVariant && selectedVariantIsinactive)){
            const nextActiveVariant = Object.values(variantsById).find(v => !v.isInactive)
            if (nextActiveVariant) onChange(nextActiveVariant.id)
        }
    }, [selectedVariant, selectedVariantIsinactive])

    useEffect(() => {
        // Scroll the selected variant into view on mount or when it changes
        if (selectedVariantRef.current) {
            selectedVariantRef.current.scrollIntoView({ 
                behavior: "smooth", 
                inline: "center", // Horizontal alignment to the center
                block: "nearest", // Avoid vertical scrolling
            });
        }
    }, [selectedVariantId]);

    if (typeof variantsById !== 'object') return "" 
    const variantList = Object.values(variantsById)
                              .sort((v1, v2) => alphabeticalSort(String(v1.variantDetails), String(v2.variantDetails)))  
                              .sort((v1, v2) => v1.price - v2.price)   
    return (
        <div className={styles.container}>
            {
                variantList
                      .map(v => {
                        const {price=0, variantDetails="", images=[], isInactive=false} = v || {}
                        const imageUrl = images && images.length >= 1 && typeof images[0] === 'object' && images[0].med ? 
                                         images[0].med
                                         :
                                         defaultImageUrl
                                        
                        return (
                            <div 
                                key={v.id} 
                                className={variantList.length > 1 ? "margin-right-05em" : ""}
                                ref={selectedVariantId === v.id ? selectedVariantRef : null} // Attach ref to the selected variant
                            >
                                <ProductVariantOption 
                                    price={price}
                                    variantDetails={variantDetails}
                                    imageUrl={imageUrl}
                                    selected={selectedVariantId === v.id}
                                    isInactive={isInactive}
                                    onClick={() => onChange(v.id)}
                                    editable={editable}
                                />
                            </div>
                        )

                      })
            }
        </div>
    )
}

export default ProductVariantPicker