import React, {useState, useEffect} from "react"

import Icon from "../../../components/Icon"
import AmountSmallAfterDecimal from "../../../components/AmountSmallAfterDecimal"
import DateRangePicker from "../../../components/DateRangePicker";
import Modal from "../../../components/Modal"

import PayeeActivityDateList from "../../../containers/PayeeActivityDateList";

import useBalance from "../../../hooks/useBalance";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';

import { DAY_IN_MILLISECONDS } from "../../../constants/datetime";
import {PAYEE_ACTIVITY_TYPE_DISBURSEMENT_COMPLETED} from "../../../constants/payeeActivty"
import {getTimestampForStartOfDay, timestampToDateString} from "../../../utils/datetimeUtils"
import { openReceiptInfo } from "../../../utils/receiptUtils";
import {shortenReference} from "../../../utils/interfaceUtils"
const Transactions = ({payeeActivity, payouts, payees, actions}) => {
    const {payeeId} = useOutletContext()
    const payee = payees.payeesById[payeeId]
    // /** calculate the current balance */
    const balance = useBalance({payeeId, payouts, actions})
    /**subscribe to a listener for the payee's activity **/
    //from 30 days ago
    const [fromDate, setFromDate] = useState(getTimestampForStartOfDay(Date.now() - (DAY_IN_MILLISECONDS * 30)))
    //to the end of today
    const [toDate, setToDate] = useState(getTimestampForStartOfDay(Date.now()) + DAY_IN_MILLISECONDS - 1)
    //update the toDate and fromDate when the DateRangePicker is toggled
    const handleChangeDateRange = dateRange => {
        const {fromDate, toDate} = dateRange
        setFromDate(fromDate)
        setToDate(toDate)
    }
    //listen to payee activity for the payee
    const [payeeActivityListener, setPayeeActivityListener] = useState(()=>()=>{})
    const listenToPayeeActivity = async () => {
        const newPayeeActivityListener = await actions.fetchSubscribeToPayeeActivityInDateRange(
            payeeId,
            fromDate,
            toDate
        )
        setPayeeActivityListener(() => newPayeeActivityListener)
    }
    useEffect(() => {
        listenToPayeeActivity()
        return () => {
            if (typeof payeeActivityListener === "function") payeeActivityListener()
        }
    }, [payeeId, fromDate, toDate])
    //filter the payee activity list to only those for this payee order descrending
    const payeeActivityInDateRange = Object.values(payeeActivity.payeeActivityById)
                              .filter(
                                  a => a.payeeId === payeeId &&
                                       a.createdAt >= fromDate && a.createdAt <= toDate
                              )
                              .sort((aA, aB) => aB.createdAt - aA.createdAt)
    //display payee activity in modal
    const [modalPayeeActivity, setModalPayeeActivity] = useState(null)
    const objectTypeMap = {
        "disbursement" : "Payout",
        "oneTimePayment" : "Payment",
        "invoice": "Invoice"
    }
    return (
        <div>
            <Helmet>
                <title>Transactions - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="monetization-on"/> Transactions</div>
            <div className="amount big">EC $<AmountSmallAfterDecimal amount={Number(balance).toFixed(2)}/></div>
            <div className="margin-top-1em">
                <DateRangePicker 
                    dateRange={{fromDate, toDate}}
                    onChange={handleChangeDateRange}   
                />
                <PayeeActivityDateList 
                    title="Transaction History"
                    payeeActivityList={payeeActivityInDateRange} 
                    searchFields={["reference", "totalXcd", "totalReceivedXcd"]}
                    searchFieldToLabelMap={
                        {
                            "reference": "Reference",
                            "totalReceivedXcd": "Amount Sent",
                            "totalXcd": "Total Earned"               
                        }
                    }
                    onClick={payeeActivity => setModalPayeeActivity(payeeActivity)}
                    getPayeeMenuActions={
                        payeeActivity => {
                            const {receiptId="", activityType=""} = payeeActivity || {}
                            const {handleId=""} = payee || {}
                            //there is no menu for disbursements
                            if (activityType === PAYEE_ACTIVITY_TYPE_DISBURSEMENT_COMPLETED) return undefined
                            return [
                                {
                                    name: "View receipt",
                                    label: "View receipt",
                                    onClick: () => openReceiptInfo(receiptId, handleId)
                                }
                            ]
                        }
                    }
                />
            </div>
            <Modal
                isOpen={Boolean(modalPayeeActivity)}
                closeModal={() => setModalPayeeActivity(null)}
                showCloseButton={false}
                className="height-fit-content max-height-100pct"
                overlayClassName="flex-center"
                closeOnOverlayClick={true}
            > 
                {
                    modalPayeeActivity ? 
                    <div className="margin-bottom-1em display-width-400px">
                        <div className="h1 margin-bottom-05em">${modalPayeeActivity.totalReceivedXcd} {modalPayeeActivity.title}</div> 
                        <div>Total Earned: <span className="font-weight-600">EC$<AmountSmallAfterDecimal amount={modalPayeeActivity.totalXcd} /></span></div>
                        <div>Amount Sent By Customer: <span className="font-weight-600">EC$<AmountSmallAfterDecimal amount={modalPayeeActivity.totalReceivedXcd} /></span></div>
                        <div>Amount Paid By Customer: <span className="font-weight-600">EC$<AmountSmallAfterDecimal amount={modalPayeeActivity.grossTotalXcd} /></span></div>
                        { modalPayeeActivity.reason ? <div className="margin-top-05em">Reason: {modalPayeeActivity.reason}</div> : ""}

                        <div className="margin-top-05em">Transaction Type: <span className="font-weight-600">{objectTypeMap[modalPayeeActivity.objectType]}</span></div>
                        <div>Reference: <span className="font-weight-600">{shortenReference(modalPayeeActivity.reference)}</span></div>
                        <div>Paid On: <span className="font-weight-600">{timestampToDateString(modalPayeeActivity.createdAt, "EEE, dd MMMM 'at' t")}</span></div>
                    </div>
                    :
                    ""
                }
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeActivity: state.payeeActivity,
    payouts: state.payouts,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Transactions)