import React from "react"
import styles from "./ActivityListItem.module.css"

const ActivityListItem = ({
    reason, 
    title, 
    details="",
    left="",
    right="",
    onClick=undefined,
    rightNotClickable=null //a component on the right of the main clickable zone
}) => { 
    const clickStyles = onClick ? styles.clickable : ""
    return (
        <div className={styles.container}>
            <div
                onClick={onClick ? onClick : () => {}}
                className={`${styles.innerContainer} ${clickStyles}`}
            >
                <div className={styles.left}>{left}</div>
                <div className={styles.description}>
                        <div>{title}</div>
                        <div className={styles.reason}>{reason}</div>
                        {
                            details ?
                            <div className={styles.details}>{details}</div>
                            :
                            null
                        }
                </div>
                <div className={styles.right}>
                        {right}
                </div>
            </div>
            {
                rightNotClickable ? 
                <div className="margin-left-5px">
                    {rightNotClickable}
                </div>
                :
                null
            }
        </div>
    )
}

export default ActivityListItem