import React, {useState} from "react"

import DataTable from "../../components/DataTable";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import TicketProductQuickActionButton from "../../components/TicketProductQuickActionButton";
import DropdownMenu from "../../components/DropdownMenu";
import Modal from "../../components/Modal";
import Icon from "../../components/Icon";

import PayeeProductVariant from "../PayeeProductVariant";

import CreatePayeeProductForm from "../../forms/CreatePayeeProductForm"
import UpdatePayeeProductForm from "../../forms/UpdatePayeeProductForm"

import withQRScanModal from "../../hocs/withQRScanModal"

import {PAYEE_PRODUCT_TYPE_TICKET} from "../../constants/payeeProducts"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {timestampToDateString} from "../../utils/datetimeUtils";
import { alphabeticalSort } from "../../utils/stringUtils";
import {getPayeeProductLink} from "../../utils/payeeProductUtils"

const PayeeProductTable = ({
                          title = "Products",
                          payeeId = "",
                          payeeHandleId = "",
                          payeeName = "",
                          transactionLimitXcd = 0,
                          emptyText = "No Products",
                          setQRScanLink = () => {},
                          setPaymentRequestMessage = () => {},
                          payeeProductsArray = [],
                          actions
}) => {
    //open the create modal to make new products
    const [createPayeeProductModalOpen, setCreatePayeeProductModalOpen] = useState(false)
    //open the update modal to update existing products
    const [updatePayeeProductModalId, setUpdatePayeeProductModalId] = useState("")
    //toggle the product's active/inactive state
    const togglePayeeProductIsInactive = async (id, isInactive) => {
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeProduct(id, {isInactive})
        actions.toggleLoading(false)
    }
    //delete the product
    const deletePayeeProduct = async (payeeProduct) => {
        if (
            Object.values(payeeProduct.variantsById).some(variant => variant.quantitySold > 0)
        ) {
            alert(`You cannot delete products that have previously been sold. Deactivate the product instead`)
            return
        }
        const deletedProductName = prompt(`Type "${payeeProduct.name}" to confirm you want to delete this product`)
        if (!deletedProductName) return
        if (
            String(deletedProductName).trim().toLowerCase() === String(payeeProduct.name).trim().toLowerCase()
        ) {
            actions.toggleLoading(true)
            await actions.fetchUpdatePayeeProduct(payeeProduct.id, {deleted: true})
            actions.toggleLoading(false)
        } else alert(`Product name is incorrect`)
    }
    //display QR scan modal
    const handleOpenLinkModal = payeeProduct => {
        const {name} = payeeProduct
        setPaymentRequestMessage(`Pay for ${name} from ${payeeName} via Shopdm Pay`)
        setQRScanLink(getPayeeProductLink(payeeProduct.handleId, payeeHandleId))
    }
    //highlight rows as grey if the product is deactivated 
    const rowHighlightFunction = payeeProduct => payeeProduct.isInactive ? "var(--grey)" : ""
    return (
        <>
        <DataTable
            dataArray={payeeProductsArray}
            fields={[
                {
                    id: 'imageUrl',
                    header: 'Image',
                    transformFunction: imageUrl => <img src={imageUrl} width={80} maxheight={80} />
                },
                {
                    id: 'name',
                    header: 'Name',
                    transformFunction: (name, id, key, payeeProduct) =>   <div>
                                                                                <div className="display-flex">
                                                                                    <div>
                                                                                        <div>{name}</div>
                                                                                        {
                                                                                            payeeProduct.productType === PAYEE_PRODUCT_TYPE_TICKET ? 
                                                                                                <TicketProductQuickActionButton 
                                                                                                    payeeProduct={payeeProduct}
                                                                                                /> 
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                    </div>
                                                                                    <div className={`iconContainer transparent`} onClick={() => handleOpenLinkModal(payeeProduct)}>
                                                                                        <Icon icon="open-in-new"/>
                                                                                    </div> 
                                                                                </div>
                                                                                
                                                                            </div>
                },
                {
                    id: 'price',
                    header: "Price",
                    transformFunction: price => <div className="font-weight-600">EC$<AmountSmallAfterDecimal amount={price}/></div>
                },
                {
                    id: 'variantsById',
                    header: "Variants",
                    hideSort: true,
                    transformFunction: (variantsById, id, key, payeeProduct) => <div>
                                                                                    {
                                                                                        Object.values(variantsById)
                                                                                              .sort((vA, vB) => alphabeticalSort(vA.variantDetails, vB.variantDetails))
                                                                                              .sort((vA, vB) => vA.price - vB.price)  
                                                                                              .map(v => (
                                                                                                <PayeeProductVariant 
                                                                                                    key={v.id}
                                                                                                    variantId={v.id}
                                                                                                    payeeProduct={payeeProduct}
                                                                                                />
                                                                                              ))
                                                                                    }
                                                                                </div>
                },
                {
                    id: 'createdAt',
                    header: 'Created At',
                    transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                },
                {
                    id: 'id', //actions
                    header: "",
                    hideSort: true,
                    transformFunction: (id, key, i, payeeProduct) => {
                                                            const menuActions = [
                                                                {
                                                                    name: "Send Product Link",
                                                                    label: "Send Product Link",
                                                                    onClick: () =>  handleOpenLinkModal(payeeProduct)
                                                                },
                                                                {
                                                                    name: "Update Product",
                                                                    label: "Update Product",
                                                                    onClick: () => setUpdatePayeeProductModalId(id)
                                                                },
                                                            ]
                                                            const {isInactive} = payeeProduct
                                                            if (isInactive) {
                                                                menuActions.push({
                                                                    name: "Activate Product",
                                                                    label: "Activate Product",
                                                                    onClick: () => togglePayeeProductIsInactive(id, !isInactive)
                                                                })
                                                            } else {
                                                                menuActions.push({
                                                                    name: "Deactivate Product",
                                                                    label: "Deactivate Product",
                                                                    onClick: () => togglePayeeProductIsInactive(id, !isInactive)
                                                                })
                                                            }
                                                            menuActions.push({
                                                                name: "Delete Product",
                                                                label: "Delete Product",
                                                                onClick: () => deletePayeeProduct(payeeProduct)
                                                            })
                                                            return (
                                                                <DropdownMenu
                                                                    menuItems={menuActions}
                                                                    MenuIcon={
                                                                        <div className={`iconContainer transparent`}>
                                                                            <Icon icon="more-vert"/>
                                                                        </div>
                                                                    }
                                                                />
                                                            )
                                                        }
                }
            ]}
            title={title}
            emptyText={emptyText}
            defaultSortBy="createdAt"
            searchFields={["name"]}
            TopRight={() => <button className="button gradient icon" onClick={() => setCreatePayeeProductModalOpen(true)}><Icon icon="add"/>New Product</button>}
            rowHighlightFunction={rowHighlightFunction}
        />
        <Modal
            isOpen={createPayeeProductModalOpen}
            closeModal={() => setCreatePayeeProductModalOpen(false)}
            showCloseButton={false}
            className="height-fit-content max-height-100pct display-width-400px"
            overlayClassName="flex-center padding-1pct"
            innerClassName="max-height-80vh overflow-y-auto"
            closeOnOverlayClick={true}
        > 
            <CreatePayeeProductForm
                // defaultValues={{name: defaultName}}
                payeeId={payeeId}
                onSuccess={() => setCreatePayeeProductModalOpen(false)}
                transactionLimitXcd={transactionLimitXcd}
            />
        </Modal>
        <Modal
            isOpen={Boolean(updatePayeeProductModalId)}
            closeModal={() => setUpdatePayeeProductModalId("")}
            showCloseButton={false}
            className="height-fit-content max-height-100pct display-width-400px"
            overlayClassName="flex-center padding-1pct"
            innerClassName="max-height-80vh overflow-y-auto"
            closeOnOverlayClick={true}
        > 
            <UpdatePayeeProductForm
                payeeProductId={updatePayeeProductModalId}
                payeeId={payeeId}
                onSuccess={() => setUpdatePayeeProductModalId("")}
                transactionLimitXcd={transactionLimitXcd}
            />
        </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(withQRScanModal(PayeeProductTable))