import React, {useState, useEffect} from "react"
import {Outlet, Link, NavLink, Navigate, useNavigate, useLocation} from "react-router-dom"

import Icon from "../../components/Icon"
import IconButton from "../../components/IconButton"
import DropdownMenu from "../../components/DropdownMenu"
import MobileNavigationBar from "../../components/MobileNavigationBar"

import Logo from "../../images/logo100x143.png"
import PayeeAccountMenu from "../../containers/PayeeAccountMenu"
import Logout from "../../containers/Logout"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import { getPayeeLink } from "../../utils/payeeUtils"
import MobileNavItem from "../../components/MobileNavItem"
import withQRScanModal from "../../hocs/withQRScanModal"
import { capitalizeAllWords } from "../../utils/stringUtils"

import contact from "../../config/contact"
const IconButtonWithPayeeQRScanModal = withQRScanModal(({setQRScanLink, setImageUrl, payeeHandleId, payeeLogoImageUrl=""}) => {
    const handleClick = () => {
        setQRScanLink(getPayeeLink(payeeHandleId))
        setImageUrl(payeeLogoImageUrl)
    }
    return <IconButton onClick={handleClick} icon="qr-code-2"  label="Scan to Pay"  buttonStyle={{"backgroundImage": "var(--primary-gradient)", "color": "var(--white)"}}/>
})

const PayeeHomeLayout = ({user, payees, actions}) => {
    //used to navigate imperatively to routes, in the more tab on the mobile nav
    const navigate = useNavigate();
    const location = useLocation();
    const goToRoute = (to="") => {
        if (location.pathname === to) return;
        navigate(to);
    };
    //get the list of payee accounts the user is authorized to access
    const authorizedPayeeAccountIdList = user.authorizedPayeeAccountIds ?
                                        Object.keys(user.authorizedPayeeAccountIds)
                                              .sort()
                                        :
                                        []
    const payeeAccountIdString = authorizedPayeeAccountIdList.join(" ")
    useEffect(() => {
        //save all of the payees that this user is authorized to access
        if (payeeAccountIdString) actions.fetchSaveMerchantPayeesInList(authorizedPayeeAccountIdList)
    }, [payeeAccountIdString])
    //if the user has an active payee account set, then use it
    const payeeId = user.activePayeeAccountId ? 
                    user.activePayeeAccountId :
                    //if they don't but they are authorized on one or more payee accounts, use the first from the list
                    authorizedPayeeAccountIdList.length > 0 ?
                    authorizedPayeeAccountIdList[0]
                    : ""
    const [payeeListener, setPayeeListener] = useState(()=>()=>{})
    //track loading of payee to know if payee does not exist or is just not yet loaded
    const [payeeLoaded, setPayeeLoaded] = useState(null)
    //listen to they payee anytime the payee handle id changes
    const listenToPayee = async () => {
        const newPayeeListener = await actions.fetchSubscribeToPayee(payeeId, () => setPayeeLoaded(true))
        setPayeeListener(() => newPayeeListener)
    }
    useEffect(() => {
        if (payeeId) listenToPayee()
        return () => {
            if (typeof payeeListener === "function") payeeListener()
        }
    }, [payeeId])
    useEffect(() => {
        if (payeeId && user.id && payeeId !== user.activePayeeAccountId) actions.fetchSetActivePayeeAccount(user.id, payeeId)   
    }, [payeeId])
    useEffect(() => {
        actions.toggleLoading(false)
        return () => {
            actions.toggleLoading(false)
        }
    }, [])
    const [payeeLinkModalOpen, setPayeeLinkModalOpen] = useState(false)
    const payee = payees.payeesById[payeeId]
    if (!user || !user.authenticated) return <Navigate replace to="/" />
    return (
        <div className="display-flex">
            {
                payee 
                ?
                <div className="navSidebar webOnly">
                    <div className="navSidebarHeader">
                        <Link to="/home">
                            <div><img className="navLogo" src={Logo} alt="SHOPDM PAY"/></div>
                        </Link>
                    </div>
                    
                        <div className="navSidebarBody">
                            <ul >
                                    <React.Fragment>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="/home">
                                                <Icon icon="home"/> Home
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="transactions">
                                                <Icon icon="monetization-on"/> Transactions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="orders">
                                                <Icon icon="shopping-cart"/> Orders
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="products">
                                                <Icon icon="local-mall"/> Products
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="catalogs">
                                                <Icon icon="storefront"/> Catalogs
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="invoices">
                                                <Icon icon="receipt"/> Invoices
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="customers">
                                                <Icon icon="account-circle"/> Customers
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="sideMenuItem" end to="payouts">
                                                <Icon icon="payment"/> Payouts
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                            </ul>
                        </div>
                </div>
                :
                null
            }
            <div className="flex-1">
                <div className="columnLayout">
                    <div className="navContainer">
                        
                        {
                            payee ?
                            <React.Fragment>
                                <div className="navHeader">
                                    <span className="mobileOnly">
                                        <Link to="/home">
                                            <div><img className="navLogo" src={Logo} alt="SHOPDM PAY"/></div>
                                        </Link>
                                    </span>
                                </div>
                                <div className="display-flex margin-bottom-1em margin-top-05em">
                                    <div className="display-flex align-items-center margin-right-1em">
                                    <IconButtonWithPayeeQRScanModal 
                                        payeeHandleId={payee.handleId} 
                                        payeeLogoImageUrl={payee.logoImageUrlMed}
                                        paymentRequestMessage={`Pay ${capitalizeAllWords(payee.name)} via Shopdm Pay`}
                                    />
                                    
                                    </div>
                                    <PayeeAccountMenu />
                                </div>
                            </React.Fragment>
                            :
                            null
                        }
                    </div>
                    <div className="main">
                        {
                            payee ?
                            <Outlet context={{payeeId}}/>
                            :
                            (!payeeId || (payeeLoaded && !payee)) ?
                            <div className="flex-center">
                                <div className="padding-1em">
                                    <div className="text-align-center"><img className="navLogo" src={Logo} alt="SHOPDM PAY"/></div>
                                    <div className="h2">Hmmm, it doesn't look like your account has access to Shopdm Pay.</div>
                                    <div className="margin-top-1em">If you're a small business in Dominica and you want to get set up to accept card payments, you can reach our team at <a href={`mailto:${contact.maintenanceEmail}?subject=I'd like to sign up for Shopdm Pay&body=Hi my name is <your name>. I represent <your business name> and I'd like to sign up for Shopdm Pay. You can call me at <your phone number>`} target="_blank" rel="noopener noreferrer">{contact.maintenanceEmail}</a> or via Whatsapp at {contact.operationsPhone}</div> 
                                    <div className="margin-top-1em">If you made a payment and you're excited about the Shopdm Pay webapp where you'll be able to see your receipts etc. <a href="https://docs.google.com/forms/d/e/1FAIpQLSdyZR4QrK38dMLuQToeKJx1fZY0hoJMDOh8qUjKphsz-_o58g/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Join the waitlist here</a></div>
                                    <div className="margin-top-1em">Otherwise, logout here, if you want to access the main site: <Logout><button className="button gradient icon icon-left"><Icon icon="logout"/>Logout from {user.email}</button></Logout> </div>

                                </div>
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>
                {
                    payee ?
                    <MobileNavigationBar>
                        <ul>
                            <MobileNavItem to="/home" icon="home" label="Home"/>
                            <MobileNavItem to="transactions" icon="monetization-on" label="Transactions"/>
                            <MobileNavItem to="orders" icon="shopping-cart" label="Orders"/>
                            <MobileNavItem to="products" icon="local-mall" label="Products"/>
                            <MobileNavItem to="catalogs" icon="storefront" label="Catalogs"/>
                            <DropdownMenu
                                menuItems={[
                                    {
                                        label: "Invoices",
                                        icon: "receipt",
                                        onClick: () => goToRoute("invoices")
                                    },
                                    {
                                        label: "Customers",
                                        icon: "account-circle",
                                        onClick: () => goToRoute("customers")
                                    },
                                    {
                                        label: "Payouts",
                                        icon: "payment",
                                        onClick: () => goToRoute("payouts")
                                    }
                                ]}
                                MenuIcon={
                                    <div>
                                        <span><Icon icon="more-vert"/></span>
                                        <span className="font-size-12px">More</span> 
                                    </div> 
                                }
                            >
                                 
                            </DropdownMenu>
                        </ul>
                    </MobileNavigationBar>
                    :
                    null
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    payees: state.payees
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeHomeLayout)