import React from "react"
import styles from "./ProductVariantOption.module.css"

const ProductVariantOption = ({price=0, variantDetails="", imageUrl="", isInactive=false, selected=false, editable=true, onClick=()=>{}}) => {
    return (
        <div 
            className={`${styles.container} ${editable ? styles.editable : ""} ${selected ? styles.selected : ""} ${isInactive ? styles.inactive : isInactive }`} 
            onClick={
                !editable ? () => {} :
                isInactive ? () => alert(`Sorry, the ${variantDetails} option is not currently available`) : 
                onClick
            }
        >
            <div className={`productImageContainer ${styles.imageContainer}`}>
                <img src={imageUrl} alt={variantDetails} />
            </div>
            <div className={styles.detailsContainer}>
                { 
                    typeof price === "number" ?
                    <div className="font-weight-600">${Number(price).toFixed(2)}</div>
                    :
                    null
                }
                <div className={styles.variantDetails}>{variantDetails}</div>
            </div>
        </div>
    )
}

export default ProductVariantOption