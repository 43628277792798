import React, {useState, useEffect} from "react"
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal"
import IncrementInput from "../../components/IncrementInput"
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder"
import ProductVariantPicker from "../../components/ProductVariantPicker"
import ProductVariantOption from "../../components/ProductVariantOption"
import SoldOut from "../../components/SoldOut"
import styles from "./ProductListItem.module.css"
import {DISPLAY_TYPE_TILE, DISPLAY_TYPE_ROW} from "../../constants/interface"

import { truncateStringWithEllipsis } from "../../utils/stringUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {useParams} from "react-router-dom"

const ProductListItem = ({
    product={},
    defaultActiveVariantId="", 
    activeVariantEditable=true, //used to stop changes to selected variant in cart 
    displayType=DISPLAY_TYPE_ROW,
    carts={},
    payeeCatalogs={},
    actions
}) => {
    const {
        id, name="", price=0, description="", 
        defaultVariantId="", variantsById={}, hasVariants=false,  
        payeeId=""
    } = product
    const params = useParams()
    const {catalogHandleId} = params
    const payeeCatalogId = payeeId && catalogHandleId && payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId] ? 
                        payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId][catalogHandleId]
                         :
                         ""
    //find the cart based on the payeeId
    const cartId = carts.cartIdByPayeeId[payeeId] || ""
    const [activeVariantId, setActiveVariantId] = useState(
        //if a default selected 
        defaultActiveVariantId && variantsById[defaultActiveVariantId] ? 
        defaultActiveVariantId
        :
        defaultVariantId
    )
    //if the default active variant changes, adjust it in state
    useEffect(() => {
        if (defaultActiveVariantId && activeVariantEditable) setActiveVariantId(defaultActiveVariantId)
    }, [defaultActiveVariantId])
    const activeVariant = variantsById[activeVariantId]
    if (!activeVariant) return ""
    const {quantityInStock=0, hasLimitedStock=false, minimumOrderQty=1} = activeVariant
    const activeImageUrl = activeVariant.images && activeVariant.images[0] && activeVariant.images[0].med ?
                            activeVariant.images[0].med
                            :
                            product.imageUrl
    const handleChangeSelectedQuantity = quantity => {
        const {price, variantDetails, quantityInStock, hasLimitedStock} = activeVariant
        const item = {
            id: activeVariant.id,
            price,
            name,
            variantDetails,
            imageUrl: activeImageUrl,
            payeeProductId: product.id,
            payeeId,
            quantityInStock,
            hasLimitedStock,
            hasVariants
        }
        actions.addToCart(item, quantity, cartId, payeeCatalogId)
    }
    
    const cart = carts.cartsById[cartId] || {}
    const {lineItemsById={}} = cart
    const cartItem = lineItemsById[activeVariantId] 
    const selectedQuantity = cartItem ? cartItem.quantity : 0
    //change color of button when it is selected
    const backgroundColor = selectedQuantity > 0 ? "var(--success-green)" : "var(--dark-grey)"

    const displayTypeStyle = displayType === DISPLAY_TYPE_ROW ? styles.row :
                             ""
    return (
        <div className={`${styles.container} ${displayTypeStyle}`}>
            <div className={styles.detailsContainer}>
                <div className={`${styles.image}`}>
                    <ImageWithPlaceholder src={activeImageUrl} containerClassName="productImageContainer"/>
                </div> 
                <div className={styles.textContainer}>
                    <div className={styles.name}>{truncateStringWithEllipsis(name, 35)}</div>
                    <div className="amount">EC$ <AmountSmallAfterDecimal amount={activeVariant.price}/></div>
                    <div className={styles.description}>{typeof description === "string" ? description.replaceAll("\\n", " "): ""}</div>
                    {
                        hasVariants & Object.keys(variantsById).length > 1  ?
                        <div className={`margin-top-05em productVariantPickerContainer`}>
                            {
                                activeVariantEditable ?
                                <ProductVariantPicker 
                                    variantsById={variantsById} 
                                    selectedVariantId={activeVariantId}
                                    defaultImageUrl={activeImageUrl}
                                    onChange={selectedVariantId => setActiveVariantId(selectedVariantId)}
                                    editable={activeVariantEditable}
                                />
                                :
                                <ProductVariantOption 
                                    price={null}
                                    variantDetails={activeVariant.variantDetails}
                                    imageUrl={activeImageUrl}
                                    selected={true}
                                    isInactive={false}
                                    onClick={() => ()=>{}}
                                    editable={false}
                                />
                            }
                        </div>
                        :
                        ""
                    }
                </div>
            </div>
            <div>
                {
                    !hasLimitedStock ||
                    quantityInStock >= minimumOrderQty ?
                    <IncrementInput 
                        value={selectedQuantity}
                        onChange={handleChangeSelectedQuantity}
                        min={0}
                        max={hasLimitedStock ? quantityInStock : undefined}
                        buttonStyle={{backgroundColor, backgroundImage: "none"}}
                    />
                    :
                    <SoldOut />
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    carts: state.carts,
    payeeCatalogs: state.payeeCatalogs
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem)