import React from "react";
import {useDispatch} from "react-redux";

import styles from "./InvoiceActionsMenu.module.css";
import {getInvoiceLink, isFullyPaidInvoice} from "../../utils/invoiceUtils";
import { openReceiptInfo } from "../../utils/receiptUtils";
import {fetchCancelInvoice} from "../../actions";
import {INVOICE_STATUS_CANCELLED} from "../../constants/invoices";
import DropdownMenu from "../../components/DropdownMenu";
import Icon from "../../components/Icon";
import withQRScanModal from "../../hocs/withQRScanModal";

import {numberToCurrencyString} from "../../utils/currencyUtils";
import { capitalizeAllWords } from "../../utils/stringUtils"

const InvoiceActionsMenu = (props) => {
    const {
        invoice: {id, currentStatus, totalXcd, billToCustomer={}}, payeeHandleId, receiptIds = [], 
        setQRScanLink = () => {},
        setPaymentRequestMessage = () => {},
        payeeName=""
    } = props;
    const {name=""} = billToCustomer
    const dispatch = useDispatch();

    const menuActions = []
    const isFullyPaid = isFullyPaidInvoice(currentStatus);

    if (isFullyPaid && receiptIds[0]) {
        menuActions.push({
            name: "View receipt",
            label: "View receipt",
            onClick: () => openReceiptInfo(receiptIds[0], payeeHandleId)
        })
    }
    if (!isFullyPaid && currentStatus !== INVOICE_STATUS_CANCELLED) {
        menuActions.push(
            {
                name: "Send Pay Link",
                label: "Send Pay Link",
                onClick: () => {
                    setQRScanLink(getInvoiceLink(id, payeeHandleId))
                    setPaymentRequestMessage(`Pay ${capitalizeAllWords(payeeName)} via Shopdm Pay`)
                }
            },
            {
                name: "Cancel Invoice",
                label: "Cancel Invoice",
                onClick: () => {
                    if (!window.confirm(`Are you sure you want to delete this $${numberToCurrencyString(totalXcd)} invoice ${name ? `to ${name}` : ""}?`)) return
                    dispatch(fetchCancelInvoice(id))
                }
            }
        )
    }

    return menuActions.length > 0 ? (
        <DropdownMenu
            menuItems={menuActions}
            MenuIcon={
                <div className={`iconContainer transparent`}>
                    <Icon icon="more-vert"/>
                </div>
            }
        />
    ) : (
        <div className={styles.menuPlaceholder}></div>
    )

}

export default withQRScanModal(InvoiceActionsMenu)