import React, {useState, useEffect} from "react";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import PayeeProduct from "../../components/PayeeProduct";
import IncrementInput from "../../components/IncrementInput";
import SoldOut from "../../components/SoldOut";
import currency from 'currency.js'
import { Skeleton } from "@mui/material";
import {sanitizeInput} from "../../utils/formValidationUtils"

const PayeeProductTotalCalculator = ({
    onChange=({totalXcd, selectedQuantity, selectionDesc, selectedItem}) => {},
    payeeProducts={}, 
    payeeProductId="",
    payeeId="",
    actions
}) => {
    //detect whether or not the product has been loaded
    const [productLoaded, setProductLoaded] = useState(Boolean(payeeProducts.payeeProductsById[payeeProductId]))
    //listen to the relevant product
    const [payeeProductListener, setPayeeProductListener] = useState(() => () => {})
    //selected variant and quantity of that variant
    const [selectedQuantity, setSelectedQuantity] = useState(1)
    const [selectedVariantId, setSelectedVariantId] = useState("")
    //subscribe to the payee product on mount and if it ever changes
    const handlePayeeProductLoaded = (payeeProduct={}) => {
        //get the default values from the product, and update state with auto calculated totals
        const {defaultVariantId=""} = payeeProduct
        let newSelectedVariantId = selectedVariantId ? selectedVariantId : defaultVariantId
        if (payeeId === payeeProduct.payeeId){
            setSelectedVariantId(newSelectedVariantId)
        }
        //finally, note that the product has been loaded 
        setProductLoaded(true)
    }
    const subscribeToPayeeProduct = async (payeeProductId="") => {
        //cancel any existing listener
        if (typeof payeeProductListener === "function") payeeProductListener()
        let newPayeeProductListener = () => {}
        if (payeeProductId) {
            //if the product is not yet loaded, set loading to true
            if (!payeeProducts.payeeProductsById[payeeProductId]) setProductLoaded(false)
            newPayeeProductListener = await actions.fetchSubscribeToPayeeProduct(
                payeeProductId, 
                payeeProduct => handlePayeeProductLoaded(payeeProduct)
            )
        }
        setPayeeProductListener(newPayeeProductListener)
    }
    useEffect(() => {
        subscribeToPayeeProduct(payeeProductId)
        const payeeProduct = payeeProducts.payeeProductsById[payeeProductId]
        if (payeeProduct) handlePayeeProductLoaded(payeeProduct)
        
        return () => {
            if (typeof payeeProductListener === 'function') payeeProductListener()
        }
    }, [payeeProductId])
    const payeeProduct = payeeProducts.payeeProductsById[payeeProductId]
    const {variantsById={}} = payeeProduct || {}
    const variant = variantsById[selectedVariantId] || {}
    useEffect(() => {
        if (!isNaN(variant.quantityInStock)){
            //check whether the existing selected quantity works with the new variant
            const {
                quantityInStock:newQuantityInStock, 
                minimumOrderQty:newMinimumOrderQty, 
                hasLimitedStock:newHasLimitedStock
            } = getPayeeProductVariantStock(payeeProduct, selectedVariantId)
            //only do complex qty in stock management if the stock is limited
            if (newHasLimitedStock){
                //default to using the selected quantity
                let newQuantity = selectedQuantity
                //if there is not enough of this variant to match the selected qty 
                //then set it to whatever lower max is available, including zero
                if (newQuantityInStock < selectedQuantity){
                    newQuantity = newQuantityInStock
                } 
                //if there is enough of this variant, but that is because zero is selected
                //and the there is enough quantity to meet the min. Set it to the min
                else if (
                    selectedQuantity === 0 && 
                    newQuantityInStock >= minimumOrderQty
                ){
                    newQuantity = minimumOrderQty
                }
                //if, in any case, this quantity is less than the minimum allowed, set it to zero 
                if (newQuantity < newMinimumOrderQty) {
                    newQuantity = 0
                }        
                setSelectedQuantity(newQuantity)
            } 
            //if there is unlimited stock and the current selected qty was zero set it to 1
            //otherwise, leave it at whatever it was
            else if (selectedQuantity === 0) setSelectedQuantity(1)
        }
    }, [variant.quantityInStock, selectedVariantId, productLoaded])
    
    useEffect(() => {
        //push the changes to the calculated values to the parent
        const payeeProduct = payeeProducts.payeeProductsById[payeeProductId] || {}
        if (payeeProduct) {
            const {price, name, imageUrl} = getProductVariantValues(payeeProduct, selectedVariantId)
            const totalXcd = currency(price).multiply(selectedQuantity).value
            const {quantityInStock, hasLimitedStock} = getPayeeProductVariantStock(payeeProduct, selectedVariantId)
            const selectedItem = selectedVariantId ?
                                {
                                    id: selectedVariantId,
                                    payeeProductId: payeeProduct.id ? payeeProduct.id : "",
                                    productType: payeeProduct.productType ? payeeProduct.productType : "",
                                    price,
                                    name,
                                    imageUrl,
                                    quantity: selectedQuantity,
                                    quantityInStock,
                                    hasLimitedStock
                                }
                                :
                                null
            const selectionDesc = `${selectedQuantity ? `${selectedQuantity} x `: ""}${sanitizeInput(name)}`
            const update = {
                totalXcd,
                selectedQuantity, 
                selectionDesc,
                selectedItem
            }
            onChange(update)
        }
    }, [payeeProductId, productLoaded, selectedQuantity, selectedVariantId])

    const getPayeeProductVariantStock = (payeeProduct, variantId="") => {
        const stock = {quantityInStock: 0, minimumOrderQty: 1, hasLimitedStock: false}
        //if there is no product, return the default stock
        if (!payeeProduct) return stock
        //use the variant id provided or fall back to the product's default variant id
        const {defaultVariantId="", variantsById={}} = payeeProduct
        variantId = variantId ? variantId : defaultVariantId
        const variant = variantsById[variantId]
        if (!variant) return stock
        else {
            //if the variant exists, get it's values and set those to the stock object
            const {quantityInStock=0, minimumOrderQty=1, hasLimitedStock=false} = variant
            return {...stock, quantityInStock, minimumOrderQty, hasLimitedStock}
        }
    }

    const getProductVariantValues = (payeeProduct, variantId="") => {
        const values = {price:0, imageUrl: "", name:""}
        //if there is no product, return the default values
        if (!payeeProduct) return values
        //set the default values to those of the general product
        values.price = payeeProduct.price
        values.name = payeeProduct.name
        values.imageUrl = payeeProduct.imageUrl
        //use the variant id provided or fall back to the product's default variant id
        const {defaultVariantId="", variantsById={}} = payeeProduct
        variantId = variantId ? variantId : defaultVariantId
        const variant = variantsById[variantId]
        if (!variant) return values
        else {
            //if the variant exists, get it's values and set those to the values object
            const {price=0, images=[], variantDetails=""} = variant
            const name = variantDetails ? `${values.name} - ${variantDetails}` : values.name
            const imageUrl = images && images.length > 0 ? images[0].med : payeeProduct.imageUrl
            return {...values, price, name, imageUrl}
        }
    }
    
    //if there is not a product id return nothing
    if (!payeeProduct) return "" 
    //if the product is loaded, but it does not exist then the id is invalid
    if (
        productLoaded &&
        (
            !payeeProduct ||
            !payeeProduct.id ||
            payeeProduct.payeeId !== payeeId
        ) 
    ){
        return <div className="text-align-center">Not Found</div> //TODO handle not found, perhaps at a higher level
    }
    if (payeeProduct.isInactive) return <div className="text-align-center">{payeeProduct.name} is unavailable</div>
    const {name, price, imageUrl} = getProductVariantValues(payeeProduct, selectedVariantId)
    const {quantityInStock, minimumOrderQty, hasLimitedStock} = getPayeeProductVariantStock(payeeProduct, selectedVariantId)
    const totalXcd = currency(price).multiply(selectedQuantity).value
    return (
        <div className="display-flex flex-direction-column align-items-center">  
            <PayeeProduct
                imageUrl={imageUrl}
                name={name}
                description={payeeProduct.description}
                selectedQuantity={selectedQuantity}
                price={price}
                totalXcd={totalXcd}
                variantsById={payeeProduct.variantsById}
                hasVariants={payeeProduct.hasVariants}
                selectedVariantId={selectedVariantId}
                onChangeSelectedVariant={
                    newSelectedVariantId => {
                        setSelectedVariantId(newSelectedVariantId)
                    }
                }
                loaded={productLoaded}
            />
            {/* <div>(selectedQuantity: {selectedQuantity} selectedVariantId: {selectedVariantId})</div> */}
            <div className="margin-top-05em">
                {
                    productLoaded ?
                    <div>
                        {
                            !hasLimitedStock || quantityInStock >= minimumOrderQty ?
                            <IncrementInput 
                                value={selectedQuantity}
                                onChange={selectedQuantity => setSelectedQuantity(selectedQuantity)}
                                min={minimumOrderQty}
                                max={hasLimitedStock ? Number(quantityInStock) : undefined}
                            />
                            :
                            <SoldOut />
                        }
                    </div>
                :
                    <Skeleton variant="rectangular" width={125} height={40} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeProductTotalCalculator)