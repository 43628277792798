import React from "react";
import ListByDate from "../../components/ListByDate";
import Icon from "../../components/Icon";

import MerchantActivityListItem from "../../components/MerchantActivityListItem";

const PayeeActivityDateList = ({
    title="Transactions", 
    payeeActivityList=[], 
    showRight=true, 
    searchFields=[], 
    searchFieldToLabelMap={},
    onClick=undefined,
    getPayeeMenuActions=(payeeActivity)=>undefined
}) => {
    
    return (
            <ListByDate 
                title={title}
                searchFields={searchFields}
                searchFieldToLabelMap={searchFieldToLabelMap}
                itemList={payeeActivityList}
                renderItem={
                            payeeActivity => <MerchantActivityListItem 
                                                payeeActivity={payeeActivity} 
                                                showRight={showRight}
                                                onClick={onClick ? () => onClick(payeeActivity) : undefined}
                                                menuActions={getPayeeMenuActions(payeeActivity)}
                                             />
                            }
                            placeholder={<div className="flex-center padding-1em margin-top-05em background-color-light-grey border-radius-8px color-secondary"><div className="icon-left display-flex align-items-center"><Icon icon="monetization-on"/> No Transactions</div></div>}
            />
    )
}

export default PayeeActivityDateList